import { redirect } from 'react-router-dom';

// Constants
import { formNaming } from '../../../../../../components/forms/NewCommunicationForm/constants';

// GraphQL
import { client } from '../../../../../../gql/client';
import { UPDATE_COMMUNICATION } from '../../../../../../gql/mutations/communication';
import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
  UPDATE_PARENT_ORDER,
} from '../../../../../../gql/mutations/orders';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';

// State
import { indexTabFormReset } from '../../../../../../state/actions/ui/tabs';
import { deselectedRowModalTable } from '../../../../../../state/actions/ui/selectRowTableModal';

// Types
import { SaveDataParams } from './types';

//Utils
import { tabsType } from '../../../../../../state/reducers/ui/tabs';
import { GET_COMMUNICATION_BY_ID } from '../../../../../../gql/queries/communication';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../../../../gql/queries/orders/predefinedData';

export const saveData = async ({
  formData,
  store,
  storedDataOTSelected,
  tabSelected,
}: SaveDataParams) => {
  const dispatch = store.dispatch;
  if (formData) {
    const registerInitDate = formData.get('registerInitDate');
    const groupInterId = formData.get('groupInter');
    const mediaId = formData.get('media');
    const sendStatus = formData.get('sendStatus');
    const concessionId = formData.get('concession');
    const roadId = formData.get('road');
    const parentOrderId = formData.get('parentOrderId');
    const trackValue = formData.get('track');
    const direction = formData.get('direction');
    const margin = formData.get('margin');
    const laneValue = formData.get('lane');
    const pkInit = formData.get('pkInit') as string;
    const pkEnd = formData.get('pkEnd') as string;
    const description = formData.get('description');

    const {
      data: { order: parentOrder },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: parentOrderId,
      },
    });

    const communicationId = parentOrder.communicationIDs[0];

    if (
      registerInitDate ||
      sendStatus ||
      groupInterId ||
      mediaId ||
      roadId ||
      concessionId ||
      trackValue ||
      direction ||
      margin ||
      laneValue ||
      pkInit ||
      pkEnd
    ) {
      await client.mutate({
        mutation: UPDATE_COMMUNICATION,
        variables: {
          input: {
            id: communicationId,
            registerInitDate: registerInitDate ? registerInitDate : undefined,
            sendStatus: sendStatus ? sendStatus : undefined,
            interlocutorGroupId: groupInterId ? groupInterId : undefined,
            mediaOfCommunicationId: mediaId ? mediaId : undefined,
            roadId: roadId ? roadId : undefined,
            concessionId: concessionId ? concessionId : undefined,
            track: trackValue ? trackValue : undefined,
            direction: direction ? direction : undefined,
            margin: margin ? margin : undefined,
            lane: laneValue ? laneValue : undefined,
            pkInitKm: pkInit ? parseInt(pkInit.split('+')[0]) : undefined,
            pkInitMeter: pkInit ? parseInt(pkInit.split('+')[1]) : undefined,
            pkEndKm: pkEnd ? parseInt(pkEnd.split('+')[0]) : undefined,
            pkEndMeter: pkEnd ? parseInt(pkEnd.split('+')[1]) : undefined,
          },
        },
      });
    }

    if (description) {
      await client.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          input: {
            id: parentOrderId,
            description: description ? description : undefined,
          },
        },
      });
    }

    if (tabSelected > 0) {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: storedDataOTSelected?.id,
        },
      });

      const [
        {
          data: { capitol },
        },
        {
          data: { subCapitol },
        },
        {
          data: { classification },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CAPITOL_BY_ID,
          variables: {
            id: order.capitolIDs[0],
          },
        }),
        client.query({
          query: GET_SUB_CAPITOL_BY_ID,
          variables: {
            id: order.subCapitolIDs[0],
          },
        }),
        client.query({
          query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
          variables: {
            id: order.classificationIDs[0],
          },
        }),
      ]);

      await client.mutate({
        mutation: UPDATE_ORDER_RELATIONSHIPS,
        variables: {
          input: {
            id: parentOrderId,
            capitol: capitol.id,
            subCapitol: subCapitol.id,
            classification: classification.id,
          },
        },
      });

      await client.mutate({
        mutation: UPDATE_PARENT_ORDER,
        variables: {
          input: {
            orderToUpdate: parentOrderId,
            newParentOrderId: storedDataOTSelected.id,
          },
        },
      });
    }

    client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_BY_ID,
      variables: { id: parentOrderId },
    });

    client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_BY_ID,
      variables: { id: parentOrder.parentExpeditionOrderIDs[0] },
    });

    client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_BY_ID,
      variables: { id: storedDataOTSelected.id },
    });

    client.query({
      fetchPolicy: 'network-only',
      query: GET_COMMUNICATION_BY_ID,
      variables: { id: communicationId },
    });
  }

  sessionStorage.removeItem(formNaming.EDIT_COMMUNICATION_FORM);
  dispatch(deselectedRowModalTable(formNaming.EDIT_COMMUNICATION_FORM));
  dispatch(indexTabFormReset(tabsType.EDIT_COMMUNICATION));
  return redirect('/communication/search-create-historical-communication');
};
